import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'schedule-picker-modal',
  templateUrl: './schedule-picker-modal.component.html',
  styleUrls: ['./schedule-picker-modal.component.scss']
})
export class SchedulePickerModalComponent {

  selectedSchedule: any = null;

  constructor(public dialogRef: MatDialogRef<SchedulePickerModalComponent>) {
  }

  assign () {
    this.dialogRef.close(this.selectedSchedule);
  }

  dismiss () {
    this.dialogRef.close(false);
  }

}