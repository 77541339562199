import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StorageService } from '../../services/storage.service';
import { FilesListComponent } from '../files-list/files-list.component';

@Component({
  selector: 'folder-selector-modal',
  templateUrl: './folder-selector-modal.component.html',
  styleUrls: ['./folder-selector-modal.component.scss']
})
export class FolderSelectorModalComponent implements OnInit {

  folderPath: string = '';
  isTrashFolder: boolean = false;
  excludedFiles: string[] = [];
  selectedItemsCount: number = 0;

  @ViewChild(FilesListComponent) filesList: FilesListComponent;

  constructor(
    public dialogRef: MatDialogRef<FolderSelectorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FolderSelectorModalData,
    private storageService: StorageService
  ) {
    this.folderPath = data.folderPath;
    this.isTrashFolder = data.isTrashFolder;
    this.excludedFiles = data.excludedFiles;
  }

  ngOnInit(): void {
    this.storageService.setSelectorType('single-folder', 'folders');
    this.storageService.storageFull = false;
  }

  close (files: any[]) {
    this.dialogRef.close(files);
    this.storageService.reset();
  }

  dismiss () {
    this.dialogRef.close(false);
    this.storageService.reset();
  }

}

interface FolderSelectorModalData {
  folderPath: string;
  isTrashFolder: boolean;
  excludedFiles: string[];
}
