import { Component, Inject, OnInit } from '@angular/core';



import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DisplayControlService } from '../../services/display-control.service';

@Component({
  selector: 'display-control',
  templateUrl: './display-control.component.html',
  styleUrls: ['./display-control.component.scss']
})
export class DisplayControlComponent implements OnInit {

  multiple: boolean;

  loading = false;
  formData: any = {};
  fullConfiguration: string = '';

  constructor(public dialogRef: MatDialogRef<DisplayControlComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { multiple: boolean },
    private displayControlService: DisplayControlService) {
      this.multiple = data.multiple;
  }

  ngOnInit(): void {
    if (this.multiple) {
      this.formData.displayControlContents = this.displayControlService.getDefaultConfiguration();
    } else {
      this._loadConfiguration();
    }
  }

  filterConfiguration(config) {
    const requiredKeys = [
      'serial-baud-rate',
      'serial-data-bits',
      'serial-parity',
      'serial-stop-bits',
      'serial-flow-control',
      'serial-screen-on-cmd',
      'serial-screen-off-cmd'
    ];

    // Split the config into lines and filter based on required keys
    const filteredConfig = config
      .split('\n')
      .filter(line => requiredKeys.some(key => line.startsWith(key)))
      .join('\n');

    return filteredConfig;
  }

  mergeConfiguration(fullConfig, filteredConfig) {
    const fullConfigLines = fullConfig.split('\n');
    const filteredConfigLines = filteredConfig.split('\n');
    const filteredConfigMap = new Map(filteredConfigLines.map(line => [line.split('=')[0], line]));

    const mergedConfig = fullConfigLines.map(line => {
      const key = line.split('=')[0];
      return filteredConfigMap.get(key) || line;
    }).join('\n');

    return mergedConfig;
  }

  _loadConfiguration() {
    this.loading = true;

    this.displayControlService.getConfiguration()
      .then((config) => {
        this.fullConfiguration = config;
        this.formData.displayControlContents = this.filterConfiguration(config);
      })
      .catch((err) => {
        console.log('Failed to load config; showing default', err);
        this.resetForm();
      })
      .finally(() => {
        this.loading = false;
      });
  }

  saveConfiguration() {
    // TODO: remove this when we have a way to save multiple displays at once
    if (this.multiple) {
      this.dialogRef.close(this.formData.displayControlContents);

      return;
    }

    this.loading = true;

    let mergedConfig = this.mergeConfiguration(this.fullConfiguration, this.formData.displayControlContents);

    if(mergedConfig.includes('interface=\n')) {
      mergedConfig = mergedConfig.replace('interface=\n', 'interface=rs232\n');
    } else if(mergedConfig.includes('interface=cec\n')) {
      mergedConfig = mergedConfig.replace('interface=cec\n', 'interface=rs232\n');
    }

    this.displayControlService.updateConfiguration(mergedConfig)
      .then(() => {
        this.dialogRef.close(false);
      })
      .catch((err) => {
        console.log('Failed to save configuration file', err);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  dismiss() {
    this.dialogRef.close(false);
  }

  resetForm() {
    const config = this.displayControlService.getDefaultConfiguration();
    this.formData.displayControlContents = this.filterConfiguration(config);
  }

}
