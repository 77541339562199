<form #bulkEditForm="ngForm" id="bulkEditForm" name="bulkEditForm" class="bulk-edit-modal" (submit)="save()">
  <div class="tw-flex tw-justify-end tw-mr-2">
    <button type="button" class="close" (click)="dismiss()" aria-label="Click to close this modal">
      <streamline-icon ariaLabel="Click to close this modal" name="close" width="12" height="12"></streamline-icon>
    </button>
  </div>

  <h4 class="mb-4">{{title}}</h4>
  <mat-dialog-content class="tw-mb-2">
    <div class="align-left">
      <div #target></div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end" class="tw-flex-col md:!tw-flex-row tw-gap-4">
    <button type="button"
      mat-stroked-button
      class="tw-w-full md:!tw-w-fit"
      (click)="cancel()">
      Cancel
    </button>
    <button type="submit"
      id="saveBulkEditForm"
      mat-flat-button
      color="primary"
      class="tw-w-full md:!tw-w-fit"
      [disabled]="bulkEditForm.invalid">
      Save
    </button>
  </mat-dialog-actions>
</form>
