import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import * as _ from 'lodash';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';


@Component({
  selector: 'bulk-delete-confirmation',
  templateUrl: './bulk-delete-confirmation.component.html',
  styleUrls: ['./bulk-delete-confirmation.component.scss']
})
export class BulkDeleteConfirmationComponent implements OnInit {
  companyItems = 0;
  subCompanyItems = 0;
  inputText = null;
  expectedText = '0';

  itemName;
  selectedItems;

  constructor(
    public dialogRef: MatDialogRef<BulkDeleteConfirmationComponent>,
    @Inject(DIALOG_DATA) data: BulkDeleteConfirmationData,
    private companyStateService: CompanyStateService) {
      this.selectedItems = data.selectedItems;
      this.itemName = data.itemName;
    }

  ngOnInit(): void {
    _.each(this.selectedItems, (display) => {
      if (display.companyId === this.companyStateService.getSelectedCompanyId()) {
        this.companyItems++;
      } else {
        this.subCompanyItems++;
      }
    });

    this.expectedText = this.selectedItems.length.toString();
  }

  delete() {
    if (this.inputText === this.expectedText) {
      this.dialogRef.close(true);
    }
  }

  dismiss() {
    this.dialogRef.close(false);
  }

}

export interface BulkDeleteConfirmationData {
  selectedItems: any[];
  itemName: string;
}
