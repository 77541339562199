import { AfterViewInit, Component, Inject, ViewChild, ViewContainerRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'bulk-edit-modal',
  templateUrl: './bulk-edit-modal.component.html',
  styleUrls: ['./bulk-edit-modal.component.scss']
})
export class BulkEditModalComponent implements AfterViewInit {

  title: string;
  baseModel: any;
  component: any;

  @ViewChild('bulkEditForm')
  public bulkEditForm: NgForm;

  @ViewChild('target', { read: ViewContainerRef })
  private target: ViewContainerRef;

  constructor(
    public dialogRef: MatDialogRef<BulkEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = data.title;
    this.baseModel = data.baseModel;
    this.component = data.component;
  }

  ngAfterViewInit(): void {
    const instance = this.target.createComponent(this.component).instance as any;
    instance.model = this.baseModel;
  }

  save() {
    this.dialogRef.close(this.baseModel);
  }

  cancel() {
    this.dialogRef.close(false);
  }

  dismiss() {
    this.dialogRef.close(false);
  }
}
