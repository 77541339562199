<form>
  <div class="tw-flex tw-justify-end tw-mr-2">
    <button type="button" mat-icon-button class="close" (click)="dismiss()" data-dismiss="modal" aria-label="Click to close this modal">
      <streamline-icon ariaLabel="Click to close this modal" name="close" width="12" height="12"></streamline-icon>
    </button>
  </div>
  <h4 class="tw-text-center">Assign Schedule</h4>
  <mat-dialog-content>

    <preview-selector [(selectedItem)]="selectedSchedule" additionalClass="mb-2" additionalTooltipClass="tooltip-sm max-z-index"></preview-selector>

  </mat-dialog-content>
  <mat-dialog-actions align="end" class="tw-gap-4">
    <button mat-stroked-button (click)="dismiss()">
      <span>Cancel</span>
    </button>
    <button id="confirm-primary" mat-flat-button color="primary" (click)="assign()" [disabled]="!selectedSchedule">
      <span>Assign</span>
    </button>
  </mat-dialog-actions>
</form>
