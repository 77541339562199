<form id="safeDeleteForm">
  <div class="tw-flex tw-justify-end tw-mr-2">
    <button type="button" mat-icon-button class="close" (click)="dismissModal()" data-dismiss="modal" aria-label="Click to close this modal">
      <streamline-icon ariaLabel="Click to close this modal" name="close" width="10" height="10"></streamline-icon>
    </button>
  </div>
  <h4 class="tw-text-center">Delete Company?</h4>
  <mat-dialog-content>
    <p>Are you sure you want to delete the company <strong>{{name}}</strong>? This operation cannot be undone! Please type DELETE to confirm.</p>
    <input id="safeDeleteInput" name="safeDeleteInput" type="text" [(ngModel)]="inputText" class="form-control" (input)="refreshInput()" />
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="tw-gap-4">
    <button mat-stroked-button type="button" (click)="dismissModal()">
      Cancel
    </button>
    <button id="deleteForeverButton" mat-flat-button color="primary" type="submit" (click)="confirm()" [disabled]="!canConfirm()">
      Delete Forever
    </button>
  </mat-dialog-actions>
</form>
