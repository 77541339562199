<div id="timelineModal">
  <div class="tw-flex tw-justify-end tw-mr-2">
    <button type="button" mat-icon-button class="close" (click)="dismiss()" aria-label="Click to close this modal">
      <streamline-icon ariaLabel="Click to close this modal" name="close" width="12" height="12"></streamline-icon>
    </button>
  </div>
  <h4 class="tw-text-center">Edit Timeline</h4>
  <mat-dialog-content>
    <form role="form" #timelineForm="ngForm" id="forms.timelineForm" name="forms.timelineForm">
      <div class="timeline">
        <section
          ngModelGroup="timelineDates"
          #timelineDates="ngModelGroup"
          dateEndAfterStart
          [startDate]="startDate"
          [endDate]="endDate"
          [skipValidation]="processing">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label class="control-label">Start Date</label>
                <mat-form-field appearance="outline" class="date-picker">
                  <input matInput
                    [matDatepicker]="picker"
                    id="startDate"
                    name="startDate"
                    [(ngModel)]="timeline.startDate"
                    ngDefaultControl
                    [required]="!timeline.always"
                    #startDate="ngModel"
                    placeholder="MM/DD/YYYY"
                    (ngModelChange)="detectChanges()" />
                  <button mat-icon-button matIconSuffix type="button" (click)="picker.open()"><i class="fa fa-calendar"></i></button>
                  <mat-datepicker
                    #picker
                    [calendarHeaderComponent]="calendarMonthYearHeader"
                    [startAt]="matDatepickerConfig.startAt"
                    [startView]="matDatepickerConfig.startView"
                    />
                  @if (startDate.invalid && startDate.touched) {
                    <mat-error>Please enter a valid date</mat-error>
                  }
                </mat-form-field>
              </div>
            </div><!--col-sm-4-->
            <div class="col-sm-6">
              <div class="form-group">
                <label class="control-label">End Date</label>
                <mat-form-field appearance="outline" class="date-picker">
                  <input matInput
                    [matDatepicker]="endDatePicker"
                    id="endDate"
                    name="endDate"
                    [(ngModel)]="timeline.endDate"
                    ngDefaultControl
                    #endDate="ngModel"
                    [min]="timeline.startDate"
                    placeholder="MM/DD/YYYY"
                    (ngModelChange)="detectChanges()" />
                  <button mat-icon-button matIconSuffix type="button" (click)="endDatePicker.open()"><i class="fa fa-calendar"></i></button>
                  <mat-datepicker #endDatePicker [calendarHeaderComponent]="calendarMonthYearHeader"></mat-datepicker>
                  @if (endDate.invalid && endDate.touched) {
                    <mat-error>Please enter a valid date</mat-error>
                  }
                  @if (timelineDates.errors?.dateEndAfterStart) {
                    <mat-error>End Date must be later than Start Date</mat-error>
                  }
                </mat-form-field>
              </div>
            </div><!--col-sm-4-->
          </div><!--row-->
        </section>
        <section>
          <pick-time-range [(allDay)]="timeline.allDay" [(startTime)]="timeline.startTime" [(endTime)]="timeline.endTime"></pick-time-range>
        </section>
        <section>
          <label class="control-label u_margin-sm-bottom" for="recurrence">
            <strong>Recurrence</strong>
          </label>
          <div class="form-group">
            <div class="flex-row">
              <mat-radio-group
                [(ngModel)]="timeline.recurrenceType"
                (change)="detectChanges()"
                name="recurrenceType"
                class="flex-row">
                <div class="tw-flex tw-items-center tw-mr-4" id="dailyRadio">
                  <mat-radio-button value="Daily" id="Daily">
                    <span class="control-label">Daily</span>
                  </mat-radio-button>
                </div>
                <div class="tw-flex tw-items-center tw-mr-4" id="weeklyRadio">
                  <mat-radio-button value="Weekly" id="Weekly">
                    <span class="control-label">Weekly</span>
                  </mat-radio-button>
                </div>
                <div class="tw-flex tw-items-center tw-mr-4" id="monthlyRadio">
                  <mat-radio-button value="Monthly" id="Monthly">
                    <span class="control-label">Monthly</span>
                  </mat-radio-button>
                </div>
                <div class="tw-flex tw-items-center" id="yearlyRadio">
                  <mat-radio-button value="Yearly" id="Yearly">
                    <span class="control-label">Yearly</span>
                  </mat-radio-button>
                </div>
              </mat-radio-group>
            </div>
          </div>
          <div class="recurrence-option">
            <div [shown]="timeline.recurrenceType === 'Daily'">
              <div class="form-group">
                <label class="control-label">Every</label>
                <input type="number"
                  class="form-control input-short"
                  id="dailyRecurrenceFrequency"
                  name="dailyRecurrenceFrequency"
                  [(ngModel)]="recurrence.daily.recurrenceFrequency"
                  (ngModelChange)="detectChanges()"
                  #dailyRecurrenceFrequency="ngModel"
                  min="1"
                  max="999"
                  [required]="timeline.recurrenceType === 'Daily'">
                <label class="control-label">Day(s)</label>
              </div><!--form group-->
              @if (dailyRecurrenceFrequency.errors) {
                <p class="text-danger pb-3">
                  Daily Recurrence Frequency must be a number between 1 and 999
                </p>
              }
            </div>
            <div [shown]="timeline.recurrenceType === 'Weekly'">
              <div class="form-group">
                <label class="control-label">Every</label>
                <input type="number"
                  class="form-control input-short"
                  id="weeklyRecurrenceFrequency"
                  name="weeklyRecurrenceFrequency"
                  [(ngModel)]="recurrence.weekly.recurrenceFrequency"
                  (ngModelChange)="detectChanges()"
                  #weeklyRecurrenceFrequency="ngModel"
                  min="1"
                  max="999"
                  [required]="timeline.recurrenceType === 'Weekly'">
                <label class="control-label">Week(s)</label>
              </div>
              @if (weeklyRecurrenceFrequency.errors) {
                <p class="text-danger pb-3">
                  Weekly Recurrence Frequency must be a number between 1 and 999
                </p>
              }
              <weekday-checkboxes [(days)]="recurrence.weekly"></weekday-checkboxes>
            </div>
            <div [shown]="timeline.recurrenceType === 'Monthly'">
              <div class="form-group flex-row">
                <mat-radio-group
                  [(ngModel)]="recurrence.monthly.recurrenceAbsolute"
                  (change)="detectChanges()"
                  name="monthlyRecurrenceAbsoluteOption"
                  class="flex-row">
                  <div class="tw-mr-2">
                    <mat-radio-button [value]="true" id="monthlyRecurrenceAbsoluteTrue">
                      <span class="control-label">Day</span>
                    </mat-radio-button>
                  </div>
                </mat-radio-group>
                <fieldset [disabled]="!recurrence.monthly.recurrenceAbsolute" class="flex-center">
                  <input type="number"
                    class="form-control input-short"
                    name="monthlyAbsoluteRecurrenceDayOfMonth"
                    [(ngModel)]="recurrence.monthly.absolute.recurrenceDayOfMonth"
                    (ngModelChange)="detectChanges()"
                    #monthlyAbsoluteRecurrenceDayOfMonth="ngModel"
                    min="1"
                    max="31"
                    [required]="timeline.recurrenceType === 'Monthly' && recurrence.monthly.recurrenceAbsolute">
                  <label class="control-label">of Every</label>
                  <input type="number"
                    class="form-control input-short"
                    name="monthlyAbsoluteRecurrenceFrequency"
                    [(ngModel)]="recurrence.monthly.absolute.recurrenceFrequency"
                    (ngModelChange)="detectChanges()"
                    #monthlyAbsoluteRecurrenceFrequency="ngModel"
                    min="1"
                    max="999"
                    [required]="timeline.recurrenceType === 'Monthly' && recurrence.monthly.recurrenceAbsolute">
                  <label class="control-label">Month(s)</label>
                </fieldset>
              </div>
              @if (monthlyAbsoluteRecurrenceDayOfMonth.errors) {
                <p class="text-danger pb-3">
                  Monthly Recurrence Day Of Month value must be between 1 and 31
                </p>
              }
              @if (monthlyAbsoluteRecurrenceFrequency.errors) {
                <p class="text-danger pb-3">
                  Monthly Recurrence Frequency must be a number between 1 and 999
                </p>
              }
              <div class="form-group flex-row">
                <mat-radio-group
                  [(ngModel)]="recurrence.monthly.recurrenceAbsolute"
                  (change)="detectChanges()"
                  name="monthlyRecurrenceAbsoluteOption"
                  class="flex-row">
                  <div class="tw-mr-2">
                    <mat-radio-button [value]="false" id="monthlyRecurrenceAbsoluteFalse">
                      <span class="control-label">The</span>
                    </mat-radio-button>
                  </div>
                </mat-radio-group>
                <fieldset [disabled]="recurrence.monthly.recurrenceAbsolute" class="flex-center">
                  <week-dropdown [(week)]="recurrence.monthly.relative.recurrenceWeekOfMonth"></week-dropdown>
                  <weekday-dropdown [(weekday)]="recurrence.monthly.relative.recurrenceDayOfWeek"></weekday-dropdown>
                  <label class="control-label">of Every</label>
                  <input type="number"
                    class="form-control input-short"
                    name="monthlyRelativeRecurrenceFrequency"
                    [(ngModel)]="recurrence.monthly.relative.recurrenceFrequency"
                    (ngModelChange)="detectChanges()"
                    #monthlyRelativeRecurrenceFrequency="ngModel"
                    min="1"
                    max="999"
                    [required]="timeline.recurrenceType === 'Monthly' && !recurrence.monthly.recurrenceAbsolute">
                  <label class="control-label">Month(s)</label>
                </fieldset>
              </div><!--form-group-->
              @if (monthlyRelativeRecurrenceFrequency.errors) {
                <p class="text-danger pb-3">
                  Monthly Relative Recurrence Frequency must be a number between 1 and 999
                </p>
              }
            </div>
            <div [shown]="timeline.recurrenceType === 'Yearly'">
              <div class="form-group flex-row">
                <mat-radio-group
                  [(ngModel)]="recurrence.yearly.recurrenceAbsolute"
                  (change)="detectChanges()"
                  name="yearlyRecurrenceAbsoluteOption"
                  class="flex-row">
                  <div class="tw-mr-2">
                    <mat-radio-button [value]="true" id="yearlyRecurrenceAbsoluteTrue">
                      <span class="control-label">Every</span>
                    </mat-radio-button>
                  </div>
                </mat-radio-group>
                <fieldset [disabled]="!recurrence.yearly.recurrenceAbsolute" class="u_nowrap">
                  <month-dropdown [(month)]="recurrence.yearly.absolute.recurrenceMonthOfYear"></month-dropdown>
                  <input type="number"
                    class="form-control input-short"
                    name="yearlyAbsoluteRecurrenceDayOfMonth"
                    [(ngModel)]="recurrence.yearly.absolute.recurrenceDayOfMonth"
                    (ngModelChange)="detectChanges()"
                    #yearlyAbsoluteRecurrenceDayOfMonth="ngModel"
                    min="1"
                    max="31"
                    [required]="timeline.recurrenceType === 'Yearly' && recurrence.yearly.recurrenceAbsolute">
                </fieldset>
              </div>
              @if (yearlyAbsoluteRecurrenceDayOfMonth.errors) {
                <p class="text-danger pb-3">
                  Yearly Recurrence Day Of Month value must be between 1 and 31
                </p>
              }
              <div class="form-group flex-row">
                <mat-radio-group
                  [(ngModel)]="recurrence.yearly.recurrenceAbsolute"
                  (change)="detectChanges()"
                  name="yearlyRecurrenceAbsoluteOption"
                  class="flex-row">
                  <div class="tw-mr-2">
                    <mat-radio-button [value]="false" id="yearlyRecurrenceAbsoluteFalse">
                      <span class="control-label">The</span>
                    </mat-radio-button>
                  </div>
                </mat-radio-group>
                <fieldset [disabled]="recurrence.yearly.recurrenceAbsolute">
                  <week-dropdown [(week)]="recurrence.yearly.relative.recurrenceWeekOfMonth"></week-dropdown>
                  <weekday-dropdown [(weekday)]="recurrence.yearly.relative.recurrenceDayOfWeek"></weekday-dropdown>
                  <label class="control-label">of</label>
                  <month-dropdown [(month)]="recurrence.yearly.relative.recurrenceMonthOfYear"></month-dropdown>
                </fieldset>
              </div><!--form-group-->
            </div>
          </div>
        </section>
      </div><!--timeline-->
    </form>
  </mat-dialog-content><!--modal-body-->
  <mat-dialog-actions align="end" class="tw-gap-4">
    <button type="button"
      id="timelineModalCancel"
      mat-stroked-button
      (click)="dismiss()">
      Cancel
    </button>
    <button type="button"
      id="timelineModalApply"
      mat-flat-button
      color="primary"
      (click)="apply()"
      [disabled]="!timelineForm.valid">
      Apply
    </button>
  </mat-dialog-actions>
</div>