<div id="renameFileFolderModal">
  <div class="tw-flex tw-justify-end tw-mr-2">
    <button type="button" mat-icon-button class="close" (click)="cancel()" aria-label="Click to close this modal">
      <streamline-icon ariaLabel="Click to close this modal" name="close" width="12" height="12"></streamline-icon>
    </button>
  </div>
  <h4 class="tw-text-center">Rename {{ isFolder ? "Folder" : "File" }}</h4>
  <mat-dialog-content autofocus="autofocus">
    <div class="form-group">
      <label for="name">{{ isFolder ? "Folder" : "File" }} Name:</label>
      <div [ngClass]="{ 'input-group': !isFolder }">
        <input #renameInput type="text" class="form-control" value="" required id="name" name="name" [(ngModel)]="renameName" (keyup)="update()" (focus)="renameInput.select()" [focusMe]="true">
        @if (!isFolder) {
          <span class="input-group-addon">{{ extension }}</span>
        }
      </div>
      <br>
      <div class="alert alert-danger" role="alert" [shown]="!validDestination">The new name cannot have slashes.</div>
      <div [shown]="errorMessage">
        <div class="alert alert-danger" role="alert">
          {{ errorMessage }}
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="tw-gap-4">
    <button id="renameFileFolderModalCancelButton" type="button" mat-stroked-button (click)="cancel()" [disabled]="isProcessing">
      Cancel
    </button>
    <button id="renameFileFolderModalSaveButton" type="submit" mat-flat-button color="primary" (click)="ok()" [disabled]="isProcessing || !validDestination">
      {{isProcessing ? 'Saving' : 'Save' }}
    </button>
  </mat-dialog-actions>
</div>
