import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { UIRouterModule } from '@uirouter/angular';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CommonHeaderModule } from '../common-header/common-header.module';

import { ComponentsModule } from '../components/components.module';
import { SharedModule } from '../shared/shared.module';
import { routes } from './schedules.routes';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ApiModule } from '../api/api.module';
import { UrlFieldComponent } from '../components/url-field/url-field.component';
import { EditorModule } from '../editor/editor.module';
import { UsernamePipe } from '../shared/pipes/username.pipe';
import { AddToScheduleModalComponent } from './components/add-to-schedule-modal/add-to-schedule-modal.component';
import { AssignUsersModalComponent } from './components/assign-users-modal/assign-users-modal.component';
import { PlaylistItemComponent } from './components/playlist-item/playlist-item.component';
import { PlaylistComponent } from './components/playlist/playlist.component';
import { PreviewSelectorComponent } from './components/preview-selector/preview-selector.component';
import { ScheduleAddComponent } from './components/schedule-add/schedule-add.component';
import { ScheduleDetailsComponent } from './components/schedule-details/schedule-details.component';
import { ScheduleFieldsComponent } from './components/schedule-fields/schedule-fields.component';
import { ScheduleListComponent } from './components/schedule-list/schedule-list.component';
import { SchedulePickerModalComponent } from './components/schedule-picker-modal/schedule-picker-modal.component';
import { ScheduleSelectorComponent } from './components/schedule-selector/schedule-selector.component';
import { ScheduleTypeSelectorComponent } from './components/schedule-type-selector/schedule-type-selector.component';
import { ShareScheduleButtonComponent } from './components/share-schedule-button/share-schedule-button.component';
import { SharedSchedulePopoverComponent } from './components/shared-schedule-popover/shared-schedule-popover.component';
import { SchedulesViewportComponent } from './schedules-viewport/schedules-viewport.component';
import { PlaylistService } from './services/playlist.service';
import { ScheduleActionsService } from './services/schedule-actions.service';

@NgModule({
  declarations: [
    ScheduleSelectorComponent,
    ScheduleListComponent,
    PreviewSelectorComponent,
    SchedulePickerModalComponent,
    ScheduleTypeSelectorComponent,
    AssignUsersModalComponent,
    ScheduleAddComponent,
    ScheduleDetailsComponent,
    ScheduleFieldsComponent,
    PlaylistComponent,
    SchedulesViewportComponent,
    ShareScheduleButtonComponent,
    SharedSchedulePopoverComponent,
    AddToScheduleModalComponent,
    ScheduleSelectorComponent,
    PlaylistItemComponent
  ],
  imports: [
    CommonModule,
    CommonHeaderModule,
    EditorModule,
    FormsModule,
    UIRouterModule.forChild(routes),
    ApiModule,
    ReactiveFormsModule,
    ComponentsModule,
    SharedModule,
    TooltipModule,
    PopoverModule.forRoot(),
    BsDropdownModule.forRoot(),
    MatMenuModule,
    MatBottomSheetModule,
    MatCheckboxModule,
    MatTooltipModule,
    UrlFieldComponent,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSelectModule,
    MatRadioModule,
    MatDialogModule
  ],
  exports: [
    ScheduleSelectorComponent,
    PreviewSelectorComponent
  ],
  providers: [
    UsernamePipe
  ]
})
export class SchedulesModule {
  //workaround for including downgraded components into build files
  //https://github.com/angular/angular/issues/35314#issuecomment-584821399

  static entryComponents = [ ScheduleTypeSelectorComponent ];
  static providers = [ ScheduleActionsService, PlaylistService ];
}
