import { Component, Injector, ViewChild } from '@angular/core';

import { NgForm } from '@angular/forms';


import { FeaturesService } from 'src/app/components/plans/features.service';
import { BroadcasterService } from 'src/app/shared/services/broadcaster.service';
import { PlaceholdersService } from '../../services/placeholders.service';

import { MatDialogRef } from '@angular/material/dialog';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { PresentationPropertiesService } from '../../services/presentation-properties.service';

@Component({
  selector: 'presentation-properties',
  templateUrl: './presentation-properties.component.html',
  styleUrls: ['./presentation-properties.component.scss'],
  providers: [
    {
      deps: ['$injector'],
      provide: '$scope',
      useFactory: ($injector: Injector) => $injector.get('$rootScope').$new()
    }
  ]
})
export class PresentationPropertiesComponent {

  @ViewChild('presentationPropertiesForm')
  public presentationPropertiesForm: NgForm;

  presentationProperties = this.presentationPropertiesService.getPresentationProperties();
  companyId = this.companyStateService.getSelectedCompanyId();
  placeholders = this.placeholdersService.getPlaceholders();

  constructor(public dialogRef: MatDialogRef<PresentationPropertiesComponent>,
    private broadcaster: BroadcasterService,
    private companyStateService: CompanyStateService,
    private presentationPropertiesService: PresentationPropertiesService,
    private placeholdersService: PlaceholdersService,
    private featuresService: FeaturesService) {
  }

  apply() {
    if (!this.presentationPropertiesForm.invalid) {
      this.presentationPropertiesService.setPresentationProperties(this.presentationProperties);
      this.dismiss();
    }
  }

  dismiss() {
    this.broadcaster.emit('presentationPropertiesDismissed');
    this.dialogRef.close(true);
  }

  get sharedTemplatesAvailable(): boolean | null {
    return this.featuresService.isFeatureAvailable(FeaturesService.FEATURE_SHARED_TEMPLATES);
  }

  showUpgradePlanModal() {
    this.featuresService.showUpgradePlanModal(FeaturesService.FEATURE_SHARED_TEMPLATES).then((result) => {
      if (result) {
        this.dialogRef.close(true);
      }
    });
  }

}
