<div id="timelineModal">
  <div class="tw-flex tw-justify-end tw-mr-2">
    <button type="button" mat-icon-button class="close" (click)="dismiss()" aria-label="Click to close this modal">
      <streamline-icon ariaLabel="Click to close this modal" name="close" width="12" height="12"></streamline-icon>
    </button>
  </div>
  <h4 class="tw-text-center">Edit Timeline</h4>

  <mat-dialog-content>
    <form role="form" #timelineForm="ngForm" id="forms.timelineForm" name="forms.timelineForm">
      <div class="timeline">

        <section>
          <pick-time-range [(allDay)]="timeline.allDay" [(startTime)]="timeline.startTime" [(endTime)]="timeline.endTime" [isUTC]="true"></pick-time-range>
        </section>

        <section>
          <div class="flex-row">
            <mat-checkbox class="mr-1" name="everyDayCheckbox" id="everyDayCheckbox" [ngModel]="timeline.everyDay" (ngModelChange)="timeline.everyDay = $event" />
            <span class="control-label u_clickable" (click)="timeline.everyDay = !timeline.everyDay">Every Day</span>
          </div>

          <weekday-checkboxes [hidden]="timeline.everyDay" [(days)]="recurrence.weekly"></weekday-checkboxes>

        </section>
      </div><!--timeline-->
    </form>
  </mat-dialog-content><!--modal-body-->

  <mat-dialog-actions align="end" class="tw-gap-4">
    <button type="button"
      id="timelineModalCancel"
      mat-stroked-button
      (click)="dismiss()">
      Cancel
    </button>
    <button type="button"
      id="timelineModalApply"
      mat-flat-button
      color="primary"
      (click)="apply()"
      [disabled]="!timelineForm.valid">
      Apply
    </button>
  </mat-dialog-actions>
</div>