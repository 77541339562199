import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'upgrade-plan',
  templateUrl: './upgrade-plan.component.html',
  styleUrls: ['./upgrade-plan.component.scss']
})
export class UpgradePlanComponent {

  currentPlan = '';
  needsPlan = '';
  planExpired = false;
  parentPlan = false;

  constructor(
    public dialogRef: MatDialogRef<UpgradePlanComponent>,
    @Inject(DIALOG_DATA) public data: UpgradePlanData,
  ) {
    this.currentPlan = data.currentPlan;
    this.needsPlan = data.needsPlan;
    this.parentPlan = data.parentPlan;
    this.planExpired = data.planExpired;
  }
}

export interface UpgradePlanData {
  currentPlan: string;
  needsPlan: string;
  parentPlan: boolean;
  planExpired: boolean;
}
