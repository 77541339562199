import { ChangeDetectorRef, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


import { ScrollingListService } from 'src/app/ajs-upgraded-providers';
import { PresentationApiService } from 'src/app/api/services/presentation-api.service';

@Component({
  selector: 'presentation-selector',
  templateUrl: './presentation-selector.component.html',
  styleUrls: ['./presentation-selector.component.scss']
})
export class PresentationSelectorComponent {
  public static PRESENTATION_SEARCH = {
    filter: ''
  };

  search: any = {
    sortBy: 'changeDate',
    reverse: true,
    name: 'Presentations',
    filter: PresentationSelectorComponent.PRESENTATION_SEARCH.filter,
    onListUpdate: () => {
      this.changeDetectorRef.detectChanges();
    }
  };

  presentations = this.ScrollingListService(this.presentationApiService.list.bind(this.presentationApiService), this.search);

  filterConfig = {
    placeholder: 'Search Presentations',
    id: 'presentationSearchInput'
  };

  constructor(public dialogRef: MatDialogRef<PresentationSelectorComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    private ScrollingListService: ScrollingListService,
    private presentationApiService: PresentationApiService) {
    }

  select(presentation) {
    this.dialogRef.close([presentation.id, presentation.name, presentation.presentationType]);
  }

  dismiss() {
    this.dialogRef.close(false);
  }

}