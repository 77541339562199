import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';
import { ComponentsModule } from '../components/components.module';
import { SharedModule } from '../shared/shared.module';

import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ApiModule } from '../api/api.module';
import { CopyUrlModalComponent } from './components/copy-url-modal/copy-url-modal.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { FilesListComponent } from './components/files-list/files-list.component';
import { FolderSelectorModalComponent } from './components/folder-selector-modal/folder-selector-modal.component';
import { ListGridToggleComponent } from './components/list-grid-toggle/list-grid-toggle.component';
import { NewFolderModalComponent } from './components/new-folder-modal/new-folder-modal.component';
import { RenameFileModalComponent } from './components/rename-file-modal/rename-file-modal.component';
import { StorageBreadcrumbComponent } from './components/storage-breadcrumb/storage-breadcrumb.component';
import { StorageGridViewComponent } from './components/storage-grid-view/storage-grid-view.component';
import { StorageHomeComponent } from './components/storage-home/storage-home.component';
import { StorageIframeComponent } from './components/storage-iframe/storage-iframe.component';
import { StorageListViewComponent } from './components/storage-list-view/storage-list-view.component';
import { StorageModalComponent } from './components/storage-modal/storage-modal.component';
import { StorageSelectorComponent } from './components/storage-selector/storage-selector.component';
import { ThumbnailImageComponent } from './components/thumbnail-image/thumbnail-image.component';
import { UploadComponent } from './components/upload/upload.component';
import { StorageFileNamePipe } from './pipes/storage-file-name.pipe';
import { StorageFileSizePipe } from './pipes/storage-file-size.pipe';
import { StorageFileTypePipe } from './pipes/storage-file-type.pipe';
import { DocumentService } from './services/document.service';
import { FileUploaderService } from './services/file-uploader.service';
import { StorageUtilsService } from './services/storage-utils.service';
import { StorageService } from './services/storage.service';
import { routes } from './storage.routes';
import { ViewportComponent } from './viewport/viewport.component';

@NgModule({
  declarations: [
    StorageGridViewComponent,
    StorageFileNamePipe,
    ThumbnailImageComponent,
    StorageListViewComponent,
    StorageFileTypePipe,
    StorageFileSizePipe,
    EmptyStateComponent,
    StorageBreadcrumbComponent,
    UploadComponent,
    ListGridToggleComponent,
    FilesListComponent,
    NewFolderModalComponent,
    CopyUrlModalComponent,
    RenameFileModalComponent,
    FolderSelectorModalComponent,
    StorageModalComponent,
    StorageIframeComponent,
    StorageSelectorComponent,
    StorageHomeComponent,
    ViewportComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    UIRouterModule.forChild(routes),
    ApiModule,
    ComponentsModule,
    SharedModule,
    MatButtonToggleModule,
    MatDialogModule,
    TooltipModule.forRoot(),
    MatCheckboxModule,
    MatButtonModule,
  ],
  exports: [
    ListGridToggleComponent
  ],
  providers: [
    StorageFileTypePipe
  ]
})
export class StorageModule {
  //workaround for including downgraded components into build files
  //https://github.com/angular/angular/issues/35314#issuecomment-584821399
  static entryComponents = [StorageGridViewComponent, StorageListViewComponent, EmptyStateComponent,
    StorageBreadcrumbComponent, UploadComponent, ListGridToggleComponent, StorageSelectorComponent, FilesListComponent];
  static providers = [FileUploaderService, StorageUtilsService, StorageService, DocumentService];
}
