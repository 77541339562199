<div id="folderSelectorModal">
  <div class="tw-flex tw-justify-end tw-mr-2">
    <button type="button" mat-icon-button id="closeButton" class="close" (click)="dismiss()" data-dismiss="modal" aria-label="Click to close this modal">
      <streamline-icon ariaLabel="Click to close this modal" name="close" width="12" height="12"></streamline-icon>
    </button>
  </div>
  <h4 id="title" class="tw-text-center">Select Destination</h4>
  <mat-dialog-content class="storage-full">
    <files-list
      [folderPath]="folderPath"
      [isListView]="true"
      [allowUpload]="false"
      [allowBatchOperations]="false"
      [excludedFiles]="excludedFiles"
      (selectedItemsCount)="selectedItemsCount = $event"
      (filesSelected)="close($event)">
    </files-list>
  </mat-dialog-content><!--modal-body-->

  <mat-dialog-actions class="tw-flex tw-justify-between tw-flex-col md:!tw-flex-row tw-gap-4">
    <button id="newFolderButton"
      type="button"
      mat-stroked-button
      class="tw-w-full md:!tw-w-fit"
      (click)="filesList.addFolder()"
      [disabled]="isTrashFolder">
      New Folder
    </button>
    <div class="tw-flex tw-flex-col md:!tw-flex-row tw-gap-4">
      <button id="folderSelectorCancelButton"
        mat-stroked-button
        type="button" 
        class="tw-w-full md:!tw-w-fit"
        (click)="dismiss()">
        Cancel
      </button>
      <button id="folderSelectorSaveButton"
        mat-flat-button
        color="primary"
        type="submit"
        class="tw-w-full md:!tw-w-fit"
        (click)="filesList.sendFiles()"
        [disabled]="selectedItemsCount < 1">
        Move
      </button>
    </div>
  </mat-dialog-actions>
</div>