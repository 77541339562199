import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TimelineBasicService } from '../timeline-basic.service';

@Component({
  selector: 'timeline-basic-modal',
  templateUrl: './timeline-basic-modal.component.html',
  styleUrls: ['./timeline-basic-modal.component.scss']
})
export class TimelineBasicModalComponent implements OnInit {

  timeline: any = {};
  recurrence: any = {};

  processing = false;

  constructor(
    public dialogRef: MatDialogRef<TimelineBasicModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { timeline: any },
    private timelineBasicService: TimelineBasicService
  ) {
    this.timeline = data.timeline;
  }

  ngOnInit(): void {
    this.timelineBasicService.timeline = this.timeline;
    this.recurrence = this.timelineBasicService.recurrence;
  }

  apply () {
    this.processing = true;
    this.timelineBasicService.save();
    this.dialogRef.close(this.timeline);
  }

  dismiss () {
    this.dialogRef.close(false);
  }
}
