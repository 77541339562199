import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';

import { StateService, UrlService } from '@uirouter/angular';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { CurrentPlanService } from './current-plan.service';
import { PlansService } from './plans.service';
import { UpgradeFeatureModalComponent } from './upgrade-feature-modal/upgrade-feature-modal.component';
import { UpgradePlanComponent } from './upgrade-plan/upgrade-plan.component';

@Injectable({
  providedIn: 'root'
})
export class FeaturesService {
  static readonly FEATURE_MONITORING = 'monitoring';
  static readonly FEATURE_ALERTS = 'alerts';
  static readonly FEATURE_BRANDING = 'branding';
  static readonly FEATURE_OVERRIDE = 'override';
  static readonly FEATURE_TEACHER_ROLE = 'teacher_role';
  static readonly FEATURE_SUBCOMPANY_DISTRIBUTION = 'subcompany_distribution';
  static readonly FEATURE_DISPLAY_CONTROL = 'display_control';
  static readonly FEATURE_SHARED_TEMPLATES = 'shared_templates';
  static readonly FEATURE_CLASSROOM_ALERTS = 'classroom_alerts';
  static readonly FEATURE_POWER_BI = 'power_bi';
  static readonly FEATURE_GOOGLE_SHEETS = 'google_sheets';
  static readonly FEATURE_ASSET_LIBRARY = 'asset_library';
  static readonly FEATURE_AUTHENTICATED_WEB_PAGES = 'authenticated_web_pages';
  static readonly FEATURE_SCREEN_SHARING = 'screen_sharing';
  static readonly BASIC_FEATURES = [];
  advancedFeatures = [
    FeaturesService.FEATURE_MONITORING,
    FeaturesService.FEATURE_ALERTS,
    FeaturesService.FEATURE_BRANDING,
    FeaturesService.FEATURE_OVERRIDE,
    FeaturesService.FEATURE_DISPLAY_CONTROL,
    FeaturesService.FEATURE_ASSET_LIBRARY,
    FeaturesService.FEATURE_GOOGLE_SHEETS,
  ];

  constructor(
    private currentPlanService: CurrentPlanService,
    private plansService: PlansService,
    private modalService: ModalService,
    private companyStateService: CompanyStateService,
    private stateService: StateService,
    private urlService: UrlService
  ) { }

  isFeatureAvailable (feature: string): boolean | null {
    if (this.companyStateService.getCopyOfSelectedCompany().unlockAllFeatures) {
      return true;
    }
    if (!this.currentPlanService.isPlanActive()) {
      return null;
    }
    return this.featureSet.indexOf(feature) >= 0;
  }

  featureRequiresPlan (feature: string): any {
    const plan = this.currentPlanService.currentPlan;
    const discounted = this.plansService.isDiscounted(plan);
    if (this.plansService.isBasicPlan(plan) && !this.featureRequiresEnterprise(feature)) {
      return this.plansService.getAdvancedPlan(discounted);
    }
    if (this.companyStateService.isK12Customer()) {
      return this.plansService.getUnlimitedPlan();
    }
    return this.plansService.getEnterprisePlan(discounted);
  }

  featureRequiresEnterprise (feature: string): boolean {
    return this.advancedFeatures.indexOf(feature) < 0;
  }

  get featureSet(): string[] {
    return this.companyStateService.getCopyOfSelectedCompany().featureNames || [];
  }

  private _upgradePlanModal(feature: string, needsPlan: any, parentPlan: boolean, planExpired: boolean): Promise<any> {
    if (feature === FeaturesService.FEATURE_POWER_BI) {
      return this.modalService.showLargeDialog(
        UpgradeFeatureModalComponent,
        {
          cta: 'Unlock Power BI with Enterprise Plan',
          title: 'Power BI Dashboards',
          description: 'Integrating PowerBI with Rise Vision enables you to showcase dynamic data visualizations securely within your digital signage templates.',
          imageSource: '/images/upgrade/power-bi-settings.png',
          background: 'power-bi-graphic',
          helpLink: 'https://help.risevision.com/hc/en-us/articles/25529563620116-How-do-I-show-a-Power-BI-dashboard-or-report-on-my-display'
        },
        { panelClass: 'upgrade-modal' }
      );
    } else if (feature === FeaturesService.FEATURE_ASSET_LIBRARY) {
      return this.modalService.showLargeDialog(
        UpgradeFeatureModalComponent,
        {
          cta: 'Unlock Stock Library with Advanced Plan',
          title: 'Stock Library',
          description: 'Access over four million royalty-free stock images and videos for your templates. Search and sort them by landscape or portrait orientation and add them to your template—content provided by Pixabay.',
          imageSource: '',
          background: 'asset-library-graphic',
          helpLink: 'https://help.risevision.com/hc/en-us/articles/25781742797076-How-do-I-upload-content-from-the-Stock-Library'
        },
        { panelClass: 'upgrade-modal' }
      );
    } else if (feature === FeaturesService.FEATURE_AUTHENTICATED_WEB_PAGES) {
      return this.modalService.showLargeDialog(
        UpgradeFeatureModalComponent,
        {
          cta: 'Unlock Authenticated Web Pages with Enterprise Plan',
          title: 'Authenticated Web Pages',
          description: 'Show web pages that require a login, ensuring personalized and confidential content while maintaining audience engagement and data integrity.',
          imageSource: '',
          background: 'web-auth-graphic',
          helpLink: 'https://help.risevision.com/hc/en-us/articles/14587288751892-How-do-I-show-a-web-page-on-my-Rise-Vision-display'
        },
        { panelClass: 'upgrade-modal' }
      );
    } else if (feature === FeaturesService.FEATURE_GOOGLE_SHEETS) {
      return this.modalService.showLargeDialog(
        UpgradeFeatureModalComponent, {
          cta: 'Unlock Google Sheets List Component with Advanced Plan',
          title: 'Google Sheets List Component',
          description: 'You can use the list component to quickly turn a spreadsheet of information into a beautiful presentation with just a few clicks.',
          imageSource: '',
          background: 'google-sheets-graphic',
          helpLink: 'https://help.risevision.com/hc/en-us/articles/13348809732244-How-do-I-upload-a-CSV-or-spreadsheet-to-Rise-Vision'
        },
        { panelClass: 'upgrade-modal' }
      );
    } else if (feature === FeaturesService.FEATURE_SCREEN_SHARING) {
      return this.modalService.showLargeDialog(
        UpgradeFeatureModalComponent,
        {
          cta: 'Unlock Screen Sharing with Enterprise Plan',
          title: 'Screen Sharing',
          description: 'Enhance collaboration, engagement, and teaching with Rise Vision\'s screen sharing. Seamlessly share, and safely invite participants to share content wirelessly from any device to any display showing Rise Vision\'s digital signage.',
          imageSource: '',
          background: 'screen-sharing-graphic',
          helpLink: 'https://help.risevision.com/hc/en-us/articles/29009023259156-How-do-I-Set-Up-Screen-Sharing-on-a-display-in-Rise-Vision'
        },
        { panelClass: 'upgrade-modal' }
      );
    }
    return this.modalService.showDialog(UpgradePlanComponent, {
      currentPlan: this.currentPlanService.currentPlan.name,
      needsPlan: needsPlan.name,
      parentPlan,
      planExpired
    });
  }

  showUpgradePlanModal(feature: string): Promise<boolean | void> {
    const needsPlan = this.featureRequiresPlan(feature);
    const planExpired = this.isFeatureAvailable(feature) === null;
    const parentPlan = !this.currentPlanService.canModifyPlan;

    return this._upgradePlanModal(feature, needsPlan, parentPlan, planExpired).then(() => {
      if (!planExpired) {
        if (feature === FeaturesService.FEATURE_POWER_BI
          || feature === FeaturesService.FEATURE_ASSET_LIBRARY
          || feature === FeaturesService.FEATURE_AUTHENTICATED_WEB_PAGES
          || feature === FeaturesService.FEATURE_GOOGLE_SHEETS
          || feature === FeaturesService.FEATURE_SCREEN_SHARING
        ) {
          this.stateService.go('apps.purchase.change-plan', {
            subscriptionId: this.currentPlanService.currentPlan.subscriptionId,
            planType: this.plansService.getPlanType(this.currentPlanService.currentPlan),
            displayCount: this.currentPlanService.currentPlan.playerProTotalLicenseCount,
            enterprise: this.featureRequiresEnterprise(feature) && !this.companyStateService.isK12Customer() ? 0 : undefined
          });
        } else {
          this.stateService.go('apps.purchase.update-plan', {
            subscriptionId: this.currentPlanService.currentPlan.subscriptionId,
            productCode: needsPlan.productCode,
            redirectTo: this.urlService.url()
          });
        }
      } else {
        this.stateService.go('apps.purchase.home', {
          productCode: needsPlan.productCode,
          enterprise: this.featureRequiresEnterprise(feature) && !this.companyStateService.isK12Customer() ? 0 : undefined
        });
      }
      return true;
    }).catch(() => {});
  }
}

angular.module('risevision.common.components')
  .factory('featuresService', downgradeInjectable(FeaturesService));
