import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';



@Component({
  selector: 'safe-delete-modal',
  templateUrl: './safe-delete-modal.component.html',
  styleUrls: ['./safe-delete-modal.component.scss']
})
export class SafeDeleteModalComponent {

  name;
  inputText = null;

  constructor(public dialogRef: MatDialogRef<SafeDeleteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { name: string },
    private changeDetectorRef: ChangeDetectorRef) {
    this.name = data.name;
    }

  dismissModal() {
    this.dialogRef.close(false);
  }

  refreshInput() {
    this.changeDetectorRef.detectChanges();
  }

  canConfirm() {
    return this.inputText === 'DELETE';
  }

  confirm() {
    if (this.canConfirm()) {
      this.dialogRef.close(true);
    }
  }

}
