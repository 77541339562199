import { Component, EventEmitter, Input, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';
import { cloneDeep } from 'lodash';
import { ModalService } from 'src/app/components/modals/modal.service';
import { TimelineBasicModalComponent } from '../timeline-basic-modal/timeline-basic-modal.component';
import { TimelineDescriptionService } from '../timeline-description.service';
import { TimelineUtilsService } from '../timeline-utils.service';

@Component({
  selector: 'timeline-basic-textbox',
  templateUrl: './timeline-basic-textbox.component.html',
  styleUrls: ['./timeline-basic-textbox.component.scss']
})
export class TimelineBasicTextboxComponent {

  private updatingModal = false;
  private updatingAlwaysCheckbox = false;

  private _timelineString: string;
  @Input() set timelineString(value: string) {
    if (!this.updatingModal && !this.updatingAlwaysCheckbox) {
      this._timelineString = value;
      this.monitoringSchedule = this.parseTimeline(this._timelineString || '');
      this.timeline = this.getTimeline(
        this.monitoringSchedule.useLocaldate,
        this.monitoringSchedule.startTime,
        this.monitoringSchedule.endTime,
        this.monitoringSchedule.recurrenceDaysOfWeek
      );
      this.timeline.label = this.timelineDescriptionService.updateBasicLabel(this.timeline);
    } else {
      this.updatingModal = false;
      this.updatingAlwaysCheckbox = false;
    }
  }
  @Input() disabled: boolean = false;
  @Output() timelineStringChange: EventEmitter<string> = new EventEmitter<string>();

  monitoringSchedule: any = {};
  timeline: any = {};

  constructor(
    private timelineDescriptionService: TimelineDescriptionService,
    private timelineUtilsService: TimelineUtilsService,
    private modalService: ModalService
  ) { }

  toggleAlways(value: boolean) {
    this.updatingAlwaysCheckbox = true;
    this.timeline.always = value;
    this.monitoringSchedule.timeDefined = !value;
    this._timelineString = this.formatTimeline(this.monitoringSchedule);
    this.timelineStringChange.emit(this._timelineString);
  }

  openModal() {
    if (this.disabled) {
      return;
    }

    this.modalService.showMediumDialog(TimelineBasicModalComponent, {
      timeline: cloneDeep(this.timeline)
    }).then((timeline) => {
      this.updatingModal = true;

      this.timeline = timeline;
      this.timeline.always = (timeline.allDay && timeline.everyDay);
      this.monitoringSchedule.timeDefined = !this.timeline.always;
      this.monitoringSchedule.startTime = timeline.startTime;
      this.monitoringSchedule.endTime = timeline.endTime;
      this.monitoringSchedule.recurrenceDaysOfWeek = timeline.recurrenceDaysOfWeek;

      this.timeline.label = this.timelineDescriptionService.updateBasicLabel(this.timeline);

      this._timelineString = this.formatTimeline(this.monitoringSchedule);
      this.timelineStringChange.emit(this._timelineString);
    }).catch(() => {});
  }

  private formatTimeline(timeline: any): string {
    const resp: any = {};

    if (!timeline || !timeline.timeDefined) {
      return '';
    }

    if (timeline.startTime || timeline.endTime) {
      const options = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      };
      const startTime = typeof timeline.startTime === 'string' ? this.timelineUtilsService.sanitizeDateString(timeline.startTime) : timeline.startTime;
      const endTime = typeof timeline.endTime === 'string' ? this.timelineUtilsService.sanitizeDateString(timeline.endTime) : timeline.endTime;
      resp.time = {
        start: timeline.startTime ?
          this.timelineUtilsService.sanitizeDateString(
            (new Date(startTime) as any).toLocaleTimeString('en-GB', options)
          ) : null,
        end: timeline.endTime ?
          this.timelineUtilsService.sanitizeDateString(
            (new Date(endTime) as any).toLocaleTimeString('en-GB', options)
          ) : null
      };
    }

    if (timeline.recurrenceDaysOfWeek && timeline.recurrenceDaysOfWeek.length > 0) {
      resp.week = timeline.recurrenceDaysOfWeek.map((day) => {
        return {
          day: day,
          active: true
        };
      });
    }

    const result = JSON.stringify(resp);

    return result !== '{}' ? result : '';
  }

  private parseTimeline(tl: string) {
    const timeline: any = {};

    if (tl && tl !== '{}') {
      const json = JSON.parse(tl);

      timeline.timeDefined = true;

      if (json.time) {
        timeline.startTime = json.time.start ? this.reformatTime(json.time.start) : null;
        timeline.endTime = json.time.end ? this.reformatTime(json.time.end) : null;
      }

      if (json.week) {
        timeline.recurrenceDaysOfWeek = [];

        json.week.forEach((d) => {
          if (d.active) {
            timeline.recurrenceDaysOfWeek.push(d.day);
          }
        });
      }
    }

    return timeline;
  }

  private reformatTime(time: string): string {
    const today = new Date();
    const fullDate = new Date(this.timelineUtilsService.sanitizeDateString(today.toLocaleDateString('en-GB', {
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    })) + ' ' + time);
    return this.timelineUtilsService.sanitizeDateString(fullDate.toLocaleString('en-GB', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    })).toUpperCase();
  }

  private getTimeline (useLocaldate, startTime, endTime, recurrenceDaysOfWeek) {
    const selectedDays = (recurrenceDaysOfWeek || []).length;
    const allDay = !startTime && !endTime;
    const everyDay = selectedDays === 0 || selectedDays === 7;
    return {
      useLocaldate: useLocaldate,
      always: allDay && everyDay,
      allDay: allDay,
      everyDay: everyDay,
      startTime: startTime || null,
      endTime: endTime || null,
      recurrenceDaysOfWeek: recurrenceDaysOfWeek || []
    };
  }

}

angular.module('risevision.common.components')
  .directive(
    'timelineBasicTextbox',
    downgradeComponent({
      component: TimelineBasicTextboxComponent
    }) as angular.IDirectiveFactory
  );
