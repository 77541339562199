import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';

import { StateService } from '@uirouter/angular';
import { ProcessErrorCode, UserAuthFactory } from 'src/app/ajs-upgraded-providers';
import { PresentationApiService } from 'src/app/api/services/presentation-api.service';
import { TemplateApiService } from 'src/app/api/services/template-api.service';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { TrackerService } from 'src/app/components/logging/tracker.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { FirstScheduleService } from 'src/app/schedules/services/first-schedule.service';
import { BroadcasterService } from 'src/app/shared/services/broadcaster.service';
import { environment } from 'src/environments/environment';
import { AddPresentationComponent } from '../components/add-presentation/add-presentation.component';
import { PresentationPropertiesComponent } from '../components/presentation-properties/presentation-properties.component';
import { DistributionParserService } from './distribution-parser.service';
import { PresentationParserService } from './presentation-parser.service';
import { PresentationUtilsService } from './presentation-utils.service';
import { PresentationsService } from './presentations.service';

@Injectable({
  providedIn: 'root'
})
export class EditorService {

  static readonly REVISION_STATUS_PUBLISHED = 'Published';
  static readonly REVISION_STATUS_REVISED = 'Revised';
  static readonly DEFAULT_LAYOUT = '<!DOCTYPE HTML PUBLIC \"-//W3C//DTD HTML 4.01 Transitional//EN\">\r\n<html>\r\n\t<head>\r\n\t\t<meta http-equiv=\"content-type\" content=\"text/html; charset=UTF-8\">\r\n\t\t<title></title>\r\n\t</head>\r\n\r\n\t<body style=\"width:1920px;height:1080px; margin: 0; overflow: hidden;\" >\r\n\t</body>\r\n\r\n<!-- Warning - Editing the Presentation Data Object incorrectly may result in the Presentation not functioning correctly -->\r\n\t<script language=\"javascript\">\n\t<!--\n\tvar presentationData = {\n\t\"presentationData\": {\n\t\t\"id\": \"\",\n\t\t\"hidePointer\": true,\n\t\t\"donePlaceholder\": \"\",\n\t\t\"placeholders\": []\n\t}\n};\n\t//-->\n\t</script>\r\n<!-- No scripts after this point -->\r\n</html>';
  static readonly JSON_PARSE_ERROR = 'JSON parse error';

  loadingPresentation = false;
  savingPresentation = false;
  errorMessage = '';
  apiError = '';
  presentation: any;
  hasUnsavedChanges = false;

  constructor(
    private userStateService: UserStateService,
    private userAuthFactory: UserAuthFactory,
    private distributionParser: DistributionParserService,
    private presentationParserService: PresentationParserService,
    private presentationUtils: PresentationUtilsService,
    private presentationApiService: PresentationApiService,
    private presentationsService: PresentationsService,
    private trackerService: TrackerService,
    private broadcaster: BroadcasterService,
    private templateApiService: TemplateApiService,
    private firstScheduleService: FirstScheduleService,
    private processErrorCode: ProcessErrorCode,
    private stateService: StateService,
    private modalService: ModalService,
    private dialog: MatDialog
  ) {
    this._init();
  }

  hasContentEditorRole () {
    return this.userStateService.hasRole('ce');
  }

  private _clearMessages () {
    this.loadingPresentation = false;
    this.savingPresentation = false;

    this.errorMessage = '';
    this.apiError = '';
  }

  private _init () {
    this.presentation = {
      layout: EditorService.DEFAULT_LAYOUT,
      id: '',
      name: 'New Presentation',
      width: 1920,
      height: 1080,
      widthUnits: 'px',
      heightUnits: 'px',
      background: undefined,
      backgroundScaleToFit: false,
      backgroundStyle: '',
      hidePointer: true,
      donePlaceholder: '',
      isTemplate: false,
      isStoreProduct: false
    };
    this.presentationParserService.parsePresentation(this.presentation);

    this._clearMessages();
  }

  newPresentation () {
    this.trackerService.presentationEvent('New Presentation');

    this._init();
  }

  private _updatePresentation (presentation) {
    this.presentation = presentation;

    this.presentationParserService.parsePresentation(this.presentation);
    this.distributionParser.parseDistribution(this.presentation);

    this.broadcaster.emit('presentationUpdated');
  }

  getPresentation (presentationId?) {

    this._clearMessages();

    //show loading spinner
    this.loadingPresentation = true;

    return this.presentationApiService.get(presentationId)
      .then((result) => {
        this._updatePresentation(result.item);
      })
      .then(() => {
        if (this.presentationParserService.hasLegacyItems) {
          this.modalService.confirm(
            'Deprecated Items',
            'This Presentation contains Gadgets, Video Items, Image Items, HTML Items or Text Items that are no longer supported.<br/><br/>You must replace them with their corresponding Widget as soon as possible.',
            'Replace Deprecated Content',
            'Cancel',
            null,
            null,
            { width: this.modalService.MODAL_MD }
          ).then(() => {
            window.open('https://help.risevision.com/hc/en-us/articles/360001275603-How-do-I-update-the-deprecated-content-items-in-my-presentation-', '_blank');
          }).catch(() => {
          });
        }
      })
      .catch((e) => {
        this._showErrorMessage('get', e);
        throw e;
      })
      .finally(() => {
        this.loadingPresentation = false;
      });

  }

  private _arrayContains (items, obj) {
    var i = items.length;
    while (i--) {
      if (items[i] === obj) {
        return true;
      }
    }
    return false;
  }

  private _updateEmbeddedIds (presentation) {
    presentation.embeddedIds = [];
    var i = presentation.placeholders && presentation.placeholders.length;

    while (i--) {
      var j = presentation.placeholders[i].items &&
        presentation.placeholders[i].items.length;
      while (j--) {
        var item = presentation.placeholders[i].items[j];
        if (item && item.type === 'presentation' &&
          !this._arrayContains(presentation.embeddedIds, item.objectData)) {
          presentation.embeddedIds.push(item.objectData);
        }
      }
    }
  }

  private _parseOrUpdatePresentation () {
    if (this.stateService.is('apps.editor.workspace.htmleditor')) {
      this.presentationParserService.parsePresentation(this.presentation);
    } else {
      this.presentationParserService.updatePresentation(this.presentation);
      this.distributionParser.updateDistribution(this.presentation);
    }

    this._updateEmbeddedIds(this.presentation);
  }

  validatePresentation () {
    if (this.presentationParserService.validatePresentation(this.presentation)) {
      return Promise.resolve();
    } else {
      this.modalService.showMessage('Invalid Presentation Data',
        'The content of your Presentation could not be parsed. Please check the syntax in HTML mode.');

      return Promise.reject({
        result: {
          error: {
            message: EditorService.JSON_PARSE_ERROR
          }
        }
      });
    }
  }

  addPresentation () {

    this._clearMessages();

    //show loading spinner
    this.loadingPresentation = true;
    this.savingPresentation = true;

    return this.validatePresentation()
      .then(() => {
        this._parseOrUpdatePresentation();

        return this.presentationApiService.add(this.presentation);
      })
      .then((resp) => {
        if (resp && resp.item && resp.item.id) {
          this.trackerService.presentationEvent('Presentation Created', resp.item.id, resp.item.name, {
            presentationType: 'Presentation',
            sharedTemplate: 'blank'
          });
          this.trackerService.presentationEvent('Presentation Published', resp.item.id, resp.item.name, {
            presentationType: 'Presentation',
            sharedTemplate: 'blank'
          });

          this.broadcaster.emit('presentationCreated');


          this.stateService.go('apps.editor.workspace.artboard', {
            presentationId: resp.item.id,
            copyOf: undefined,
          }, {
            notify: false,
            location: 'replace'
          }).then(() => {
            this.firstScheduleService.create(resp.item);
          });
          return resp.item.id;
        }
      })
      .catch((e) => {
        this._showErrorMessage('add', e);
        throw e;
      })
      .finally(() => {
        this.loadingPresentation = false;
        this.savingPresentation = false;
      });
  }

  updatePresentation () {
    this._clearMessages();

    //show loading spinner
    this.loadingPresentation = true;
    this.savingPresentation = true;

    return this.validatePresentation()
      .then(() => {
        this._parseOrUpdatePresentation();

        return this.presentationApiService.update(this.presentation.id, this.presentation);
      })
      .then((resp) => {
        this.trackerService.presentationEvent('Presentation Updated', resp.item.id,
          resp.item.name);

        this._updatePresentation(resp.item);
        this.presentationsService.resetPresentation(resp.item);

        return resp.item.id;
      })
      .catch((e) => {
        this._showErrorMessage('update', e);
        throw e;
      })
      .finally(() => {
        this.loadingPresentation = false;
        this.savingPresentation = false;
      });
  }

  save () {
    if (this.presentation.id) {
      return this.updatePresentation();
    } else {
      return this.addPresentation();
    }
  }

  deletePresentationByObject (presentationObject, forceDelete?) {
    return this.presentationApiService.delete(presentationObject.id, forceDelete)
      .then(() => {
        this.trackerService.presentationEvent('Presentation Deleted', presentationObject.id, presentationObject.name);
      });
  }

  deletePresentation () {
    this._clearMessages();

    //show loading spinner
    this.loadingPresentation = true;

    this.deletePresentationByObject(this.presentation)
      .then(() => {
        this.broadcaster.emit('presentationDeleted');

        this.presentation = {};

        this.stateService.go('apps.editor.list');
      })
      .catch((e) => {
        this._showErrorMessage('delete', e);
      })
      .finally(() => {
        this.loadingPresentation = false;
      });
  }

  copyPresentationToCompany (presentation, companyId) {
    this._clearMessages();

    return this.presentationApiService.copy(presentation.id, companyId);
  }

  isRevised () {
    return this.presentation.revisionStatusName ===
      EditorService.REVISION_STATUS_REVISED;
  }

  publishPresentation () {
    this._clearMessages();

    //show loading spinner
    this.loadingPresentation = true;
    this.savingPresentation = true;

    return this.presentationApiService.publish(this.presentation.id)
      .then((presentationId) => {
        this.trackerService.presentationEvent('Presentation Published',
          this.presentation.id, this.presentation.name);

        this.presentation.revisionStatusName =
          EditorService.REVISION_STATUS_PUBLISHED;
        this.presentation.changeDate = new Date();
        this.presentation.changedBy = this.userStateService.getUsername();
      })
      .catch((e) => {
        this._showErrorMessage('publish', e);
        throw e;
      })
      .finally(() => {
        this.loadingPresentation = false;
        this.savingPresentation = false;
      });
  }

  confirmRestorePresentation () {
    return this.modalService.confirm('Restore Presentation',
      'Are you sure you want to Restore this Presentation to its Published version? All changes will be lost.')
      .then(() => {
        this.restorePresentation();
      })
      .catch(() => {});
  }

  restorePresentation () {
    this._clearMessages();

    //show loading spinner
    this.loadingPresentation = true;

    return this.presentationApiService.restore(this.presentation.id)
      .then((result) => {
        this.trackerService.presentationEvent('Presentation Restored',
          this.presentation.id, this.presentation.name);

        this._updatePresentation(result.item);
      })
      .catch((e) => {
        this._showErrorMessage('restore', e);
        throw e;
      })
      .finally(() => {
        this.loadingPresentation = false;
      });
  }

  copyPresentation () {
    var copyOf = this.presentation.id;

    this.trackerService.presentationEvent((this.presentation.isTemplate ? 'Template' : 'Presentation') + ' Copied',
      this.presentation.id, this.presentation.name, {
        presentationType: this.presentation.presentationType
      });

    this.presentation.id = undefined;
    this.presentation.name = 'Copy of ' + this.presentation.name;
    this.presentation.revisionStatusName = undefined;
    this.presentation.isTemplate = false;
    this.presentation.isStoreProduct = false;

    this.stateService.go('apps.editor.workspace.artboard', {
      presentationId: 'new',
      copyOf: copyOf,
      isLoaded: true
    });
  }

  addFromProductId (productId, trackingParams?: any) {
    return this.templateApiService.getTemplate(productId)
      .then((result) => {
        if (result.item && result.item.productCode) {
          return Promise.resolve(result.item);
        } else {
          return Promise.reject({
            result: {
              error: {
                message: 'Invalid Product Id'
              }
            }
          });
        }
      })
      .then((productDetails) => {
        return this.addFromProduct(productDetails, {
          addedFrom: trackingParams ? trackingParams.utm_campaign ? 'Campaign' : 'External' : 'Internal',
          utm_campaign: trackingParams?.utm_campaign,
          utm_source: trackingParams?.utm_source
        });
      }, (err) => {
        this._showErrorMessage('add', err);
        this.stateService.go('apps.editor.list');
        throw err;
      });
  }

  addFromProduct (productDetails?, trackingData?: any) {
    const isHtml = this.presentationUtils.isHtmlTemplate(productDetails);
    if (!productDetails || (!productDetails.rvaEntityId && !isHtml)) {
      return;
    }
    if (!isHtml) {
      return this.copyTemplate(productDetails.rvaEntityId);
    } else {
      return this.stateService.go('apps.editor.templates.edit', {
        presentationId: 'new',
        productId: productDetails.productId,
        productDetails: productDetails,
        trackingData
      });
    }
  }

  copyTemplate (rvaEntityId?) {
    if (!rvaEntityId) {
      return;
    }

    return this.getPresentation(rvaEntityId)
      .then(() => {
        this.copyPresentation();
      })
      .catch((err) => {
        this.stateService.go('apps.editor.list');
        throw err;
      });
  }

  private _getPreviewUrl (presentationId) {
    if (presentationId) {
      return environment.VIEWER_URL + '/?type=presentation&id=' + presentationId;
    }
    return null;
  }

  preview (presentationId?) {
    this.trackerService.presentationEvent('Preview Presentation', this.presentation.id,
      this.presentation.name);

    window.open(this._getPreviewUrl(presentationId),
      'rvPresentationPreview');
  }

  saveAndPreview () {
    return this.validatePresentation()
      .then(() => {
        this.userAuthFactory.removeEventListenerVisibilityAPI();
        window.open('/loading-preview.html', 'rvPresentationPreview');

        return this.save().then((presentationId) => {
          this.preview(presentationId);
        }).finally(() => {
          this.userAuthFactory.addEventListenerVisibilityAPI();
        });
      });
  }

  private _showErrorMessage (action, e) {
    this.errorMessage = 'Failed to ' + action + ' Presentation.';
    this.apiError = this.processErrorCode('Presentation', action, e);

    console.error(this.errorMessage, e);

    this.modalService.showMessage(this.errorMessage, this.apiError);
  }

  openPresentationProperties() {
    return this.modalService.showMediumDialog(PresentationPropertiesComponent);
  }

  addPresentationModal(date?: Date) {
    this.trackerService.presentationEvent('Add Presentation');
    this.modalService.showFullScreenDialog(
      AddPresentationComponent, 
      { selectedDate: date }
    );
  }
}

angular.module('risevision.editor.services')
  .factory('editorService', downgradeInjectable(EditorService));
