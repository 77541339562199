<div id="companyRoleModal">
  <h2 id="companyRoleTitle" class="tw-text-start tw-px-8">Help us personalize your experience</h2>
  <mat-dialog-content>
    <form #companyRoleForm="ngForm" id="forms.companyRoleForm" role="form" name="forms.companyRoleForm" autocomplete="on" novalidate>
      <div class="form-group">
        <label for="company-role" class="control-label">
          Tell us your Company Role to help us make your experience great *
        </label>
        <select name="companyRole" id="company-role" class="form-control selectpicker" [(ngModel)]="user.companyRole" autofocus required>
          <option value="" [shown]="false">&lt; Select Role &gt;</option>
          @for (role of COMPANY_ROLE_FIELDS; track role) {
            <option value="{{role[1]}}">{{role[0]}}</option>
          }
        </select>
      </div>
    </form>
  </mat-dialog-content> <!-- //Modal Body -->
  <mat-dialog-actions align="center" class="tw-gap-4">
    <button id="saveButton"
      type="submit"
      mat-flat-button
      color="primary"
      class="tw-w-full"
      aria-label="Apply"
      tabindex="1"
      form="forms.companyRoleForm"
      (click)="save()"
      [disabled]="companyRoleForm.invalid">
      Apply
      <i class="fa fa-check icon-right"></i>
    </button>
  </mat-dialog-actions>
</div>
