<div id="distributionModal">
  <div class="tw-flex tw-justify-end tw-mr-2">
    <button type="button" mat-icon-button class="close" (click)="dismiss()" aria-label="Click to close this modal">
      <streamline-icon ariaLabel="Click to close this modal" name="close" width="12" height="12"></streamline-icon>
    </button>
  </div>
  <h4 class="tw-text-center">Edit Distribution</h4>
  <mat-dialog-content>
    <distribution-list
      [(distribution)]="distribution"
      [(subcompanyDistribution)]="subcompanyDistribution"
      [(distributeToSubcompanies)]="distributeToSubcompanies"
      [enableSubcompanies]="enableSubcompanies"
      [enableFeatureCheck]="enableFeatureCheck"
      (closeEvent)="dismiss()">
    </distribution-list>
  </mat-dialog-content>

  <mat-dialog-actions
    class="tw-flex tw-flex-col md:tw-justify-between md:!tw-flex-row tw-gap-4">
    <a mat-button class="tw-w-full md:!tw-w-fit" (click)="dismiss()" uiSref="apps.displays.add">
      Add Display
    </a>
    <div class="tw-flex tw-flex-col md:!tw-flex-row tw-gap-4">
      <button mat-stroked-button id="distributionModalCancelButton" type="button" class="tw-w-full md:!tw-w-fit" (click)="dismiss()">Cancel</button>
      <button mat-flat-button color="primary" id="applyButton" type="button" class="tw-w-full md:!tw-w-fit" (click)="apply()">Apply</button>
    </div>
  </mat-dialog-actions>
</div>