<div id="companyIcpModal">
  <h2 id="icpModalTitle" class="tw-text-start tw-px-8">Help us personalize your experience</h2>
  <mat-dialog-content>
    <form #companyIcpForm="ngForm" id="forms.companyIcpForm" role="form" name="forms.companyIcpForm" autocomplete="on" novalidate>
      <div class="form-group">
        <label for="company-industry" class="control-label">
          Tell us your Industry to help us make your Display look great *
        </label>
        <select #companyIndustry="ngModel" id="company-industry" class="form-control selectpicker" [(ngModel)]="company.companyIndustry" name="companyIndustry" autofocus required>
          <option [value]="undefined" hidden>&lt; Select Industry &gt;</option>
          @for (industry of COMPANY_INDUSTRY_FIELDS; track industry) {
            <option value="{{industry[1]}}">{{industry[0]}}</option>
          }
        </select>
      </div>
    </form>
  </mat-dialog-content> <!-- //Modal Body -->
  <mat-dialog-actions align="center" class="tw-gap-4">
    <button id="saveButton"
      type="submit"
      mat-flat-button
      color="primary"
      class="tw-w-full"
      aria-label="Apply"
      tabindex="1"
      form="forms.companyIcpForm"
      (click)="save()"
      [disabled]="companyIcpForm.invalid">
      Apply
      <i aria-hidden="true" class="fa fa-check icon-right"></i>
    </button>
  </mat-dialog-actions>
</div>
