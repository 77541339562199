import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CalendarMonthYearHeaderComponent } from '../../calendar-month-year-header/calendar-month-year-header.component';
import { TimelineService } from '../timeline.service';

@Component({
  selector: 'timeline-modal',
  templateUrl: './timeline-modal.component.html',
  styleUrls: ['./timeline-modal.component.scss']
})
export class TimelineModalComponent implements OnInit {

  timeline: TimelineModalData['timeline'];
  recurrence: any;
  /** Material Design calendar header component for date picker customization */
  calendarMonthYearHeader = CalendarMonthYearHeaderComponent;

  processing = false;
  today = new Date();
  matDatepickerConfig = {
    startView: 'month',
    startAt: new Date(),
  };

  constructor(public dialogRef: MatDialogRef<TimelineModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TimelineModalData,
    private changeDetectorRef: ChangeDetectorRef,
    private timelineService: TimelineService) {
    this.timeline = data.timeline;
  }

  ngOnInit(): void {
    this.timelineService.timeline = this.timeline;
    this.recurrence = this.timelineService.recurrence;

    if (this.timeline.startDate && this.timeline.startDate.getTime() < this.today.getTime()) {
      this.today = new Date(this.timeline.startDate.getTime());
    }
  }

  detectChanges () {
    this.changeDetectorRef.detectChanges();
  }

  apply () {
    this.processing = true;
    this.timelineService.save();
    this.dialogRef.close(this.timeline);
  }

  dismiss () {
    this.dialogRef.close(false);
  }

}

export interface TimelineModalData {
  timeline: {
    startDate: Date;
    endDate?: Date;
    recurrence: any;
    allDay: boolean;
    startTime: Date;
    endTime: Date;
    recurrenceType: string;
    always: boolean;
    useLocaldate: boolean;
  };
}
