<div id="configure-control-modal">
  <div class="tw-flex tw-justify-end tw-mr-2">
    <button type="button" mat-icon-button class="close" (click)="dismiss()" aria-label="Click to close this modal">
      <streamline-icon ariaLabel="Click to close this modal" name="close" width="12" height="12"></streamline-icon>
    </button>
  </div>
  <h4 class="tw-text-center">Configure Display Control</h4>
  <mat-dialog-content>
    <a class="info-link madero-link" href="https://help.risevision.com/hc/en-us/articles/115005727826" target="_blank">
      <i class="fa fa-question-circle icon-left" aria-hidden="true"></i>Need help configuring Display Control?
    </a>

    <textarea class="form-control u_margin-md-top" rows="12" [(ngModel)]="formData.displayControlContents"></textarea>

    <a id="resetButton" class="madero-link" href="#" (click)="resetForm()">
      Reset To Defaults
    </a>
  </mat-dialog-content>

  <mat-dialog-actions align="end" class="tw-gap-4 tw-flex-col md:!tw-flex-row">
    <button type="button" mat-stroked-button id="copyPresentationCancelButton" class="tw-w-full md:!tw-w-fit" (click)="dismiss()">
      <span>Cancel</span>
    </button>
    <button id="saveButton" mat-flat-button color="primary" type="submit" class="tw-w-full md:!tw-w-fit"
            (click)="saveConfiguration()" [disabled]="loading" *requireRole="'da'"
            rvSpinner="saving-display-control" [rvShowSpinner]="loading">
      {{ loading ? 'Saving' : 'Save'}}
    </button>
  </mat-dialog-actions>
</div>
