import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'distribution-modal',
  templateUrl: './distribution-modal.component.html',
  styleUrls: ['./distribution-modal.component.scss']
})
export class DistributionModalComponent {

  distribution: string[];
  subcompanyDistribution: string[];
  distributeToSubcompanies: boolean;
  enableSubcompanies: boolean = false;
  enableFeatureCheck: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<DistributionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.distribution = data.distribution;
    this.subcompanyDistribution = data.subcompanyDistribution;
    this.distributeToSubcompanies = data.distributeToSubcompanies;
    this.enableSubcompanies = data.enableSubcompanies;
    this.enableFeatureCheck = data.enableFeatureCheck;
  }

  apply () {
    this.dialogRef.close({
      distribution: this.distribution,
      subcompanyDistribution: this.subcompanyDistribution,
      distributeToSubcompanies: this.distributeToSubcompanies
    });
  }

  dismiss () {
    this.dialogRef.close(false);
  }

}