import { Component, Inject } from '@angular/core';


import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyIcpService } from '../../services/company-icp.service';

@Component({
  selector: 'company-role-modal',
  templateUrl: './company-role-modal.component.html',
  styleUrls: ['./company-role-modal.component.scss']
})
export class CompanyRoleModalComponent {

  constructor(
    public dialogRf: MatDialogRef<CompanyRoleModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.user = data.user;
  }

  user: any = {};
  COMPANY_ROLE_FIELDS = CompanyIcpService.EDUCATION_COMPANY_ROLE_FIELDS;

  save() {
    this.dialogRf.close({
      user: this.user
    });
  }

}
