import { Component, Inject } from '@angular/core';


import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyIcpService } from '../../services/company-icp.service';

@Component({
  selector: 'company-icp-modal',
  templateUrl: './company-icp-modal.component.html',
  styleUrls: ['./company-icp-modal.component.scss']
})
export class CompanyIcpModalComponent {

  constructor(
    public dialogRef: MatDialogRef<CompanyIcpModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.company = data.company;
  }

  company: any = {};
  COMPANY_INDUSTRY_FIELDS = CompanyIcpService.COMPANY_INDUSTRY_FIELDS;

  save() {
    this.dialogRef.close({
      company: this.company
    });
  }

}
