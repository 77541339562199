import { Component, EventEmitter, Input, Output } from '@angular/core';

import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';

import { StorageUtilsService } from '../../services/storage-utils.service';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'storage-selector',
  templateUrl: './storage-selector.component.html',
  styleUrls: ['./storage-selector.component.scss']
})
export class StorageSelectorComponent {

  @Input() type: string;
  @Input() label: string = '';
  @Input() selected: boolean = false;
  @Output() picked: EventEmitter<string[]> = new EventEmitter<string[]>();

  constructor (
    private storageService: StorageService,
    private storageUtilsService: StorageUtilsService
  ) {}

  open () {
    this.storageService.showStorageModal(this.type).then((files) => {
      this.picked.emit(this.storageUtilsService.getFileUrls(files === 'url' ? null : files));
    });
  }

}

angular.module('risevision.storage.directives')
  .directive(
    'storageSelector',
    downgradeComponent({
      component: StorageSelectorComponent
    }) as angular.IDirectiveFactory
  );
