import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BsModalService } from 'ngx-bootstrap/modal';
import { firstValueFrom, map, take, tap } from 'rxjs';
import { BulkEditModalComponent } from './bulk-edit-modal/bulk-edit-modal.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { MessageBoxComponent } from './message-box/message-box.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  readonly MODAL_SM = '365px';
  readonly MODAL_DEFAULT = '460px';
  readonly MODAL_MD = '580px';
  readonly MODAL_LG = '70%';

  constructor(
    private modalService: BsModalService,
    private dialogService: MatDialog
  ) { }

  showMessage(title: string, message: string) {
    const initialState = {
      title,
      message
    };
    return firstValueFrom(this.openDialog<boolean>(
      MessageBoxComponent,
      initialState,
      { width: this.MODAL_SM }
    ).afterClosed().pipe(
      take(1),
    ));
  }

  confirm(
    title: string,
    message: string,
    confirmButton = 'Ok',
    cancelButton = 'Cancel',
    style = '',
    returnFocusId = '',
    config: MatDialogConfig = {}) {
    const initialState = {
      title,
      message,
      confirmButton,
      cancelButton,
    };
    return firstValueFrom(this.openDialog<boolean | null>(
      ConfirmModalComponent,
      initialState,
      { panelClass: style, ...config }
    ).afterClosed().pipe(
      take(1),
      tap(() => {
        const focusElement = returnFocusId
          ? document.getElementById(returnFocusId)
          : null;

        focusElement && focusElement.focus();
      }),
      map(result => {
        if (!result) throw new Error('');
        return true;
      }),
    ));
  }

  confirmDanger(title: string, message: string, confirmButton = 'Ok', cancelButton = 'Cancel') {
    const initialState = {
      confirmButtonClass: 'warn',
      title,
      message,
      confirmButton,
      cancelButton
    };
    return firstValueFrom(this.openDialog<boolean | null>(
      ConfirmModalComponent,
      initialState,
      { width: this.MODAL_DEFAULT }
    ).afterClosed().pipe(
      take(1),
      map(result => {
        if (!result) throw new Error('');
        return true;
      }),
    ));
  }

  showBulkEditModal(title: string, baseModel: any, component: any): Promise<any> {
    return this.showMediumDialog(BulkEditModalComponent, {
      baseModel,
      title,
      component
    });
  }

  showDialog<R = any>(
    component: ComponentType<any>,
    data?: any,
    config?: MatDialogConfig,
    returnFocusId?: string
  ) {
    return firstValueFrom(
      this.openDialog<R>(component, data, config)
        .afterClosed()
        .pipe(
          take(1),
          tap(() => {
            const focusElement = returnFocusId
              ? document.getElementById(returnFocusId)
              : null;
    
            focusElement && focusElement.focus();
          }),
          map(result => {
            if (!result) throw new Error('');
            return result;
          }),
        )
    );
  }

  showFullScreenDialog(
    component: ComponentType<any>,
    data?: any,
    config?: MatDialogConfig
  ) {
    return firstValueFrom(
      this.openDialog(component, data, {
        panelClass: ['full-screen'],
        position: { bottom: '0' },
        width: undefined,
        ...config
      }).afterClosed()
    );
  }

  showLargeDialog(
    component: ComponentType<any>,
    data?: any,
    config?: MatDialogConfig,
    returnFocusId?: string
  ) {
    const newConfig = {
      width: undefined,
      ...config,
      panelClass: ['large-dialog', ...(config?.panelClass || [])]
    };

    return this.showDialog(component, data, newConfig, returnFocusId);
  }

  showMediumDialog(
    component: ComponentType<any>,
    data?: any,
    config?: MatDialogConfig,
    returnFocusId?: string
  ) {
    const newConfig = {
      width: this.MODAL_MD,
      ...config,
    };

    return this.showDialog(component, data, newConfig, returnFocusId);
  }

  private openDialog<R = any>(
    component: ComponentType<any>,
    data?: any,
    config?: MatDialogConfig
  ) {
    const dialogRef = this.dialogService.open<{}, {}, R>(component, {
      closeOnNavigation: true,
      hasBackdrop: true,
      width: this.MODAL_DEFAULT,
      data,
      ...config,
    });

    return dialogRef;
  }

}